import React from 'react';
import $ from 'jquery';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../node_modules/bootstrap/dist/js/bootstrap.bundle';
import Nav from './Nav';
import Home from './Home';
import About from './About';
import Contact from './Contact';
import Footer from './Footer';
import Page404 from './Page404';
import {Switch, Route, Redirect} from 'react-router-dom';
import SimpleReactLightbox from 'simple-react-lightbox';

const App = () => {
  return(
      <>
        <SimpleReactLightbox>
          <Nav />
          <Switch>
           <Route exact path="/" component={Home} />
           <Route exact path="/home" component={Home} />
           <Route exact path="/about" component={About} />
           <Route exact path="/contact" component={Contact} />
           <Route exact path="/404" component={Page404} />
           <Redirect to="/404" />
          </Switch>
          <Footer />
        </SimpleReactLightbox>
      </>
    );
};

export default App;