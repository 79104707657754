import React from 'react';

const Flags = () => {
  return(
      <>
        <p>For all GCC Countries</p>
        <table>
            <tr>
                <td>
                    <span className="btn btn-sm btn-light mr-1 flag-text" data-toggle="modal" data-target="#myModal">
                        <span class="flag-icon flag-icon-ae mr-1"></span>
                        UAE
                    </span>
                </td>
                <td>
                    <span className="btn btn-sm btn-light mr-1 flag-text" data-toggle="modal" data-target="#myModal">
                        <span class="flag-icon flag-icon-sa mr-1"></span>
                        KSA
                    </span>
                </td>
                <td>
                    <span className="btn btn-sm btn-light mr-1 flag-text" data-toggle="modal" data-target="#myModal">
                        <span class="flag-icon flag-icon-bh mr-1"></span>
                        Bahrain
                    </span>
                </td>
                <td>
                    <span className="btn btn-sm btn-light mr-1 flag-text" data-toggle="modal" data-target="#myModal">
                        <span class="flag-icon flag-icon-kw mr-1"></span>
                        Kuwait
                    </span>
                </td>
                <td>
                <span className="btn btn-sm btn-light mr-1 flag-text" data-toggle="modal" data-target="#myModal">
                    <span class="flag-icon flag-icon-om mr-1"></span>
                    Oman
                </span>
                </td>
                <td>
                    <span className="btn btn-sm btn-light mr-1 flag-text" data-toggle="modal" data-target="#myModal">
                        <span class="flag-icon flag-icon-qa mr-1"></span>
                        Qatar
                    </span>
                </td>
            </tr>
        </table>
        <br />
      </>
    );
};

export default Flags;