import React, {useState} from 'react';
import {NavLink} from 'react-router-dom';
import {Link} from 'react-scroll';
import emailjs from 'emailjs-com';

const Nav = () => {

	
  function sendEmail(e) {
    e.preventDefault();

    emailjs.sendForm('gmail', 'mail_id', e.target, 'user_nIYvTxuBcyY5jVVVFqdqf')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
      e.target.reset();
      var actionBtn = document.getElementById("actionBtn");
  	  actionBtn.setAttribute("style", "display: none;");
      var form = document.getElementById("formBody");
  	  form.classList.add("formBody");
      var alert = document.getElementById("alertBody");
  	  alert.classList.remove("alertBody");
  }

  return(
      <>
        <nav className="navbar navbar-expand-md navbar-light fixed-top">
			  <NavLink className="navbar-brand" to="/">ITD CMS</NavLink>
			  <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
			    <span className="navbar-toggler-icon"></span>
			  </button>

			  <div className="collapse navbar-collapse" id="navbarSupportedContent">
			    <ul className="navbar-nav ml-auto">
			      <li className="nav-item">
			        <Link activeclass="active" to="features" spy={true} smooth={true} offset={-70} duration={500} className="nav-link topNavMenu">Features</Link>
			      </li>
			      <li className="nav-item">
			        <a exact className="nav-link requestloginbtn btn btn-sm" href="rental/">Already Member</a>
			      </li>
			      <li className="nav-item">
			        <NavLink exact className="nav-link requestdemobtn btn btn-sm btn-dark" to="#" data-toggle="modal" data-target="#myModal">Request Demo</NavLink>
			      </li>
			    </ul>
			  </div>
		</nav>

		  <div className="modal fade" id="myModal">
		    <div className="modal-dialog">
		      <div className="modal-content bg-light">
		      
		        <div className="modal-header">
		          <h4 className="modal-title text-info">Request&nbsp;Demo</h4>
		          <button type="button" className="close" data-dismiss="modal">&times;</button>
		        </div>
		        <div id="alertBody" className="modal-body alertBody">
			        <div className="alert alert-primary">
			        	<h6>Thanks for your message, we'll contact you back shortly</h6>
			        </div>
		        </div>
		        <form onSubmit={sendEmail}>
			        <div id="formBody" className="modal-body">
					  <div className="form-group">
					    <label for="companyname">Company Name</label>
					    <input type="Company" className="form-control" id="companyname" name="company" required="true"/>
					  </div>
					  <div className="form-group">
					    <label for="emailaddress">Email Address</label>
					    <input type="email" className="form-control" id="emailaddress" name="emailaddress" required="true"/>
					    <small id="emailHelp" className="form-text text-muted">We'll never share your email with anyone else.</small>
					  </div>
					  <div className="form-group">
					    <label for="mobile">Mobile Phone</label>
					    <input type="Company" className="form-control" id="mobile" name="mobile" required="true"/>
					  </div>
			        </div>
			        
			        <div className="modal-footer">
			          <button type="button" className="btn btn-danger" data-dismiss="modal">Close</button>
					  <button id="actionBtn" type="submit" className="btn btn-primary">Submit</button>
			        </div>
		        </form>
		      </div>
		    </div>
		  </div>
      </>
    );
};

export default Nav;