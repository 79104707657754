import React from 'react';
import firstScreen from '../src/images/1.png';
import mockup from '../src/images/mockup.jpg';
import invoices from '../src/images/invoices.png';
import reports from '../src/images/reports.png';
import features from '../src/images/features.png';
import SimpleReactLightbox, { SRLWrapper } from "simple-react-lightbox";
import SimpleSlider from "./SimpleSlider";

const Home = () => {
  return(
      <>
	      <div className="wrapper">
	      		<div id="MainHeader">
		        	<SimpleSlider />
		        </div>
	        	<div className="tabHeading">
	        		<div className="container">
		        		<div className="row">
			        		<div className="col-md-12">
				        		<span>Access from any device</span>
				        		<p>PC, Tablets & Mobiles</p>
			        		</div>
		        		</div>
	        		</div>
	        	</div>
        		<section>
        			<img src={mockup} alt="mockup" className="img-fluid mockup"/>
    			</section>
        		<section id="features">
	        		<div className="container-fluid">
		        		<div className="row">
			        		<div className="col-md-12 features-tab">
			        			<div className="row">
			        				<div className="col-md-12 invoices-text">
			        					<h3 className="mt-2">Features</h3>
			        				</div>
		        				</div>
		        				<div className="row">
		        					<div className="col-md-6 order-1 featureslist-box">
		        						<div className="row">
		        							<div className="col-md-1"></div>
					        				<div className="col-md-5 features-list">
		        								<ul className="list-group">
												  <li className="list-group-item">Rental Management</li>
												  <li className="list-group-item">Accounting System</li>
												  <li className="list-group-item">Customer Records</li>
												  <li className="list-group-item">Car Records</li>
												  <li className="list-group-item">Export Records</li>
												  <li className="list-group-item">Notifications</li>
												  <li className="list-group-item">Toll Gate History</li>
												</ul>
					        				</div>
					        				<div className="col-md-5 features-list">
		        								<ul className="list-group">
												  <li className="list-group-item">Traffic Fines History</li>
												  <li className="list-group-item">Payment History</li>
												  <li className="list-group-item">Attachments</li>
												  <li className="list-group-item">Purchase Inventory</li>
												  <li className="list-group-item">Invoice Generator</li>
												  <li className="list-group-item">Pdfs invoices & Payment receipts</li>
												  <li className="list-group-item">Reports</li>
												</ul>
					        				</div>
					        				<div className="col-md-1"></div>
				        				</div>
			        				</div>
			        				<div className="col-md-6 order-md-1">
        								<img src={features} alt="features" className="img-fluid"/>
			        				</div>
			        			</div>
			        		</div>
		        		</div>
	        		</div>
	        	</section>
        		<section>
	        		<div className="container-fluid">
		        		<div className="row">
			        		<div className="col-md-6 invoices-tab">
			        			<div className="row">
			        				<div className="col-md-12 invoices-text">
			        					<h3 className="mt-2">Invoices</h3>
			        					<h6>Invoice Generator, Quik View & Pdfs.</h6>
			        				</div>
			        				<div className="col-md-12 invoices-img">
				        				<img src={invoices} alt="Invoices" className="img-fluid"/>
			        				</div>
			        			</div>
			        		</div>
			        		<div className="col-md-6 reports-tab">
			        			<div className="row">
			        				<div className="col-md-12 invoices-text">
			        					<h3 className="mt-2">Reports</h3>
			        					<h6>General reports, specific reports for cars and customers.</h6>
			        				</div>
			        				<div className="col-md-12 invoices-img">
				        				<img src={reports} alt="Reports" className="img-fluid"/>
			        				</div>
			        			</div>
			        		</div>
		        		</div>
	        		</div>
	        	</section>
	      </div>
      </>
    );
};

export default Home;