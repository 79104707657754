import react from 'react';

const Footer = () => {
  return(
      <>
        <footer className="text-lg-start">

		  <div className="p-3">
			 <p>
			    All Rights Reserved © 2021 Copyright
			    <span> ITD CMS</span>
		    </p>
		  </div>
		</footer>
      </>
    );
};

export default Footer;