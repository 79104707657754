import React from 'react';

const About = () => {
  return(
      <>
        <div className="container-fluid wrapper">
		      <div className="container">
		      	<div className="row">
		      		<div className="col-12">
		        		<h1> About! </h1>
		        	</div>
		        </div>
		      </div>
	      </div>
      </>
    );
};

export default About;