import React, { Component } from "react";
import Slider from "react-slick";
import firstScreen from '../src/images/1.png';
import mobview from '../src/images/mobView1.png';
import Flags from './Flags';
import SimpleReactLightbox, { SRLWrapper } from "simple-react-lightbox";

export default class SimpleSlider extends Component {
  render() {
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
      autoplay:true,
      autoplaySpeed:3000,
    };
    return (
      <div>
        <Slider {...settings}>
          <div>
            <section className="headerSliderSection">
              <Flags />
              <div className="row">
                  <div className="col-md-5 order-1 homeHeaderLeft">
                    <h2>Car Rental Management System</h2>
                    <hr className="headerDivier" />
                    <h4>Fully functional</h4>
                    <button className="btn btn-primary btn-lg getstartedbtn" data-toggle="modal" data-target="#myModal">Get Started</button>
                  </div>
                  <div className="col-md-7 order-md-1 homeHeaderRight">
                      <img src={firstScreen} className="img-fluid rounded" alt="Screen 1" />
                  </div>
              </div>
            </section>
          </div>
          <div>
            <section className="headerSliderSection">
              <Flags />
              <div className="row">
                  <div className="col-md-5 order-1 homeHeaderLeft">
                    <h2>Mobile Friendly</h2>
                    <hr className="headerDivier" />
                    <h4>Fully responsive even in data tables</h4>
                    <button className="btn btn-primary btn-lg getstartedbtn" data-toggle="modal" data-target="#myModal">Get Started</button>
                  </div>
                  <div className="col-md-7 order-md-1 homeHeaderRight">
                      <img src={mobview} className="img-fluid rounded" alt="Screen 2" />
                  </div>
              </div>
            </section>
          </div>
        </Slider>
      </div>
    );
  }
}